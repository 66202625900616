<template>
  <div class="animate__backInRight magazine">
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <label>magazines</label>
      <h4 class="text-lg font-semibold">All Magazines</h4>
    </div>
    <div>
    <div class="flex-col w-full bg-white shadow-sm rounded-lg">
      <div class=" w-full  flex items-center justify-between rounded-lg px-4 mt-4 mb-3 py-3">
      <div class="w-2/6 relative">
        <i class="fa-solid fa-magnifying-glass text-gray-400 text-xl absolute top-2 left-3"></i>
        <input placeholder="Search by Books Name or ID"
          class="p-2.5 pl-5 mb-1 w-full text-sm border rounded-lg" type="search" v-model="query_search"
          @input="onSearch" v-on:keyup.enter="onSearch" />
        <small class="pt-3" v-if="typing"> <em> typing ... </em> </small>
      </div>
      <div class="relative w-3/4 flex justify-end">
        <button 
          class="text-white mr-3 bg-blue-500 border focus:outline-none font-medium rounded-lg text-sm px-2 py-2.5 text-center"
          type="button">
          <router-link
          class="text-white"
                  :to="{ name: MagazineCreatePath.name }"
                >
                  <i class="fa fa-plus"></i> Add New
                </router-link>
        </button>
        <button @click="toggleDropdown" 
          class="text-gray-500 bg-white border focus:outline-none font-medium rounded-lg text-sm px-2 py-2.5 text-center"
          type="button"> <i class="fa-solid fa-filter"></i> Filter <i class="fa-solid fa-chevron-down"></i>
        </button>
        <FilterComponent
          :isDropdownVisible.sync="isDropdownVisible"
          @reloadPage="reloadPage"
          @input="handleFilterInput"
        />
  </div>

    </div>
    <div >
      <TableBooks class="border-b border-gray-50 " id="my-table" :books="magazines" :currentPage="currentPageTransaction" :perPage="perPageTransactions" :isLoading="isLoading"
      :reloadPage="reloadPage" @sort-by="handleSortBy" />
    </div>
    <div class="flex bg-white justify-between pl-5 pr-3 pt-4 mb-5 border-t border-gray-100 mt-1">
        <div class="font-semibold">
          <span class="text-gray-500 font-normal">Showing </span> {{currentPageTransaction}}-{{perPageTransactions}} <span class="text-gray-500 font-normal">of</span> {{ formatPriceBasic(totalRows) }}
        </div>
        <div class="mb-2">
          <t-pagination 
          :total-items="Number(totalRows)"
          :per-page="perPageTransactions"
          v-model="currentPageTransaction"
          @change="onClickPage" 
          />
        </div>
    </div>
    </div>
  </div>
   
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TableBooks from '../../components/ebook/table.vue';
import { MagazineCreatePath } from '../../router/content';
import constant from '../../store/constant';
//import ContentTypeSelector from '../../components/form/ContentTypeSelector.vue';
//import SortComponent from '../../components/form/SortComponent.vue'
import FilterComponent from '../../components/form/FilterComponent.vue'

//import { EventBus } from '../../event-bus';
import 'flowbite';
import 'flowbite/dist/flowbite.css';

const ITEM_STATUS = Object.entries(constant.ITEM_STATUS).map((item) => {
  return { text: item[0], value: item[1] };
});

const ITEM_CONTENT_TYPE = Object.entries(constant.ITEM_CONTENT_TYPE).map((item) => {
  return { text: item[0], value: item[1] };
});
const { ROLES } = constant;

export default {
  name: 'magazine',
  components: {
    TableBooks,
 //   SortComponent,
    FilterComponent,
 //   ContentTypeSelector,
  },
  data() {
    return {
      MagazineCreatePath,
      query_search: null,
      isDropdownVisible: false,
      currentPageTransaction: 1,
      perPageTransactions: 50,
      contentType:null,
      createdSort:null,
      vendor: [],
      vendorSearchOptions: [],
      isVendorSearch: false,
      status: null,
      statusOptions: [
          { text: '---Status [All]---', value: null },
          ...ITEM_STATUS,
        ],
      contentTypeOptions: [
        { text: '---Content Type [All]---', value: null },
        ...ITEM_CONTENT_TYPE,
      ],
      debounce: null,
      typing: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Magazine | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  created() {
    this.actionFirst();
  },
  computed: {
    ...mapGetters('auth', { auth: 'auth' }),
    ...mapGetters('magazine', ['magazines']),
    ...mapState({
      isLoading: (state) => state.magazine.isLoading,
      isError: (state) => state.magazine.isError,
      totalRows: (state) => state.magazine.totalRows,
    }),
  },
  methods: {
    ...mapActions('vendors', ['searchVendors']),
    ...mapActions('magazine', ['fetchMagazine', 'searchMagazine']),
    actionFirst() {
      const query = this.$route.query;
      this.currentPage = query.page ?? this.currentPage;
      this.perPage = query.limit ?? this.perPage;
      this.query_search = query.q ?? this.query_search;
      this.status = query.status ?? this.status;
      this.contentType = query.contentType ?? this.contentType;
      if (typeof(query.vendor_id) == "string") {
        const vendor = query.vendor_id.split("#");
        this.vendor = [{id: vendor[0], name: vendor[1]}];
      } else {
        this.vendor = (query.vendor_id ?? []).map(val => {
          const vendor = val.split("#");
          return {
            id: vendor[0],
            name: vendor[1],
          }
        });
      }
      this.reloadPage();
    },
    actionGetMagazines() {
      this.query_search = null;
      this.vendor = [];
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchMagazine(payload);
      this.setQuery(payload);
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    onSearch(event) {
      this.query_search = event.target.value;
      this.currentPage = 1;
      if (!this.query_search && !this.vendor.length) {
        this.actionGetMagazines();
        clearTimeout(this.debounce);
        return;
      }

      this.typing = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = false;
        this.actionSearchMagazine();
      }, 600);
    },
    actionSearchMagazine() {
      const payload = {
        q: this.query_search ?? '',
        page: this.currentPage,
        limit: this.perPage,
        content_type:this.contentType,
        vendor_id: this.vendor.map((val) => val.id),
        sort:this.createdSort,
        status: this.status,
      };
      this.searchMagazine(payload);
      payload.vendor_id = this.vendor.map((val) => `${val.id}#${val.name}`);
      this.setQuery(payload);
    },
    onClearSearch() {
      this.query_search = null;
      this.vendor = [];
      this.status = null;
      this.contentType = null;
      this.createdSort = null
      this.currentPage = 1;
      this.reloadPage();
    },
    onClickPage(page) {
      this.currentPage = page;
      this.reloadPage();
    },
    handleFilterInput({ contentType, status, vendor }) {
      this.contentType = contentType;
      this.status = status;
      this.vendor = vendor;
      this.reloadPage();
    },
    vendorFind(query) {
      if (!query) return;

      this.isVendorSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          limit: 10,
        };
        this.searchVendors(payload)
          .then((response) => {
            this.vendorSearchOptions = response.data.data.rows;
            this.isVendorSearch = false;
          })
          .catch(() => {
            this.isVendorSearch = false;
          });
      }, 600);
    },
    reloadPage() {
      if (!this.query_search && !this.vendor.length && !this.status && !this.contentType && !this.createdSort)
        this.actionGetMagazines();
      else this.actionSearchMagazine();
    },
    vendorChange() {
      this.currentPage = 1;
      this.reloadPage();
    },
    changeContentType() {
      this.reloadPage();
    },
    handleSortBy(criteria) {
    this.createdSort = criteria;
    this.actionSearchMagazine();
  
    },
    vendorRemove() {
      this.vendor = [];
      if (this.query_search) {
        this.actionSearchMagazine();
      } else {
        this.actionGetMagazines();
      }
    },
    statusChange() {
      this.reloadPage();
    },
    permFilterVendor() {
      switch (this.auth.role_id) {
        case ROLES.SUPER_ADMIN:
          return true;
        case ROLES.INTERNAL_ADMIN:
          return true;
        case ROLES.INTERNAL_DATA_ENTRY:
          return true;
        default:
          return false;
      }
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    setQuery(q = {}) {
      this.$router.push({ path: this.$route.path, state: q }).catch(() => {});
    }
  },
};
</script>

<style scoped>
.magazine {
  font-family: 'Avenir Next', 'effra', Helvetica, Arial, sans-serif;
}
</style>
