var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative inline-block text-left w-full"
  }, [_c('button', {
    staticClass: "inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.toggleDropdown
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.selectedOptionText))]), _c('svg', {
    staticClass: "ml-2 -mr-1 h-5 w-5",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M19 9l-7 7-7-7"
    }
  })])]), _vm.isOpen ? _c('div', {
    staticClass: "absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  }, [_c('div', {
    staticClass: "p-1"
  }, _vm._l(_vm.contentTypeOptions, function (option) {
    return _c('label', {
      key: option.value,
      staticClass: "flex items-center p-2 cursor-pointer hover:bg-gray-100"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedContentType,
        expression: "selectedContentType"
      }],
      staticClass: "form-radio h-4 w-4 text-blue-600",
      attrs: {
        "type": "radio"
      },
      domProps: {
        "value": option.value,
        "checked": _vm._q(_vm.selectedContentType, option.value)
      },
      on: {
        "change": [function ($event) {
          _vm.selectedContentType = option.value;
        }, _vm.handleChange]
      }
    }), _c('span', {
      staticClass: "ml-2 text-gray-700"
    }, [_vm._v(_vm._s(option.text))])]);
  }), 0)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }