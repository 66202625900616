<template>
  <div class="relative inline-block text-left w-full">
    <button
      type="button"
      @click="toggleDropdown"
      class="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
    >
      <span> {{ selectedOptionText }}</span>
      <svg class="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
      </svg>
    </button>

    <!-- Dropdown Menu -->
    <div v-if="isOpen" class="absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <div class="p-1">
        <label
          v-for="option in contentTypeOptions"
          :key="option.value"
          class="flex items-center p-2 cursor-pointer hover:bg-gray-100"
        >
          <input
            type="radio"
            :value="option.value"
            v-model="selectedContentType"
            @change="handleChange"
            class="form-radio h-4 w-4 text-blue-600"
          />
          <span class="ml-2 text-gray-700">{{ option.text }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentTypeSelector',
  props: {
    value: String,
    options: Array
  },
  data() {
    return {
      isOpen: false,
      selectedContentType: this.value || '',
      contentTypeOptions: this.options
    };
  },
  computed: {
    selectedOptionText() {
      const selectedOption = this.contentTypeOptions.find(option => option.value === this.selectedContentType);
      return selectedOption ? selectedOption.text : 'Select Content Type';
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    handleChange() {
      this.isOpen = false; 
      this.$emit('input', this.selectedContentType);
    },
    handleClickOutside(event) {
      if (this.isOpen && !this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  watch: {
    value(newValue) {
      this.selectedContentType = newValue;
    }
  }
};
</script>
