var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Filter by Vendor",
      "close-on-select": false,
      "multiple": true,
      "options": _vm.options,
      "loading": _vm.loading
    },
    on: {
      "search-change": _vm.handleSearchChange,
      "input": _vm.handleInput
    },
    scopedSlots: _vm._u([{
      key: "clear",
      fn: function () {
        return [_vm.selected.length ? _c('div', {
          staticClass: "multiselect__clear",
          on: {
            "mousedown": function ($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return _vm.handleClear.apply(null, arguments);
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }